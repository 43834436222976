<template>
  <div class="">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        Schliessen
        <!--        {{ $t("Notification.Schliessen") }}-->
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <div class="bodyheader-tv1">
      TV 3
    </div>

    <div class="div-kategorien">


      <div v-for="(item,index) in product.category">
        <div  v-if="item.tv === tv">

          <v-divider style="margin-bottom: 15px; background: red"></v-divider>
          <v-text-field
              v-model="item.name"
              label="Kategorie"
              solo
              color="var(--Secondary)"
              background-color="blue"
          />
          <div v-for="(it,ind) in item.productList">
            <div style="display: grid; margin-bottom: 30px;">


              <v-text-field
                  v-model="it.nr"
                  label="Nummer"
                  solo

                  color="var(--Secondary)"
              />

              <v-text-field
                  v-model="it.product"
                  label="Produkt"
                  solo
                  color="var(--Secondary)"
              />

              <v-text-field
                  v-model="it.description"
                  label="Untertitel"
                  solo
                  color="var(--Secondary)"
              />

              <v-text-field
                  v-model="it.price"
                  label="Preis"
                  solo
                  color="var(--Secondary)"
              />
              <template>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                          v-model="it.allergien"
                          :items="allergien"
                          :item-text=" item => '(' + item.kurzel + ') - '+ item.text "
                          label="Allergien"
                          multiple
                          outlined
                          dense
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </template>


              <template>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                          v-model="it.zusatzstoffe"
                          :items="zusatzstoffe"
                          :item-text=" item => '(' + item.kurzel + ') - '+ item.text "
                          label="Zusatzstoffe"
                          multiple
                          outlined
                          dense
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </div>
            <v-divider style="margin-bottom: 30px"></v-divider>
          </div>

          <div style="display: flex; gap: 20px; justify-content: center">
            <div v-if="product.category[index].productList.length <productCounter[index]">
              <v-btn color="green" @click="addProduct(index)">+ Hinzufügen</v-btn>
            </div>
          </div>

        </div>

      </div>



      <div v-if="categoryCounter < anzahlCategorienTV3">
        <v-divider style="margin: 20px 0"></v-divider>
        <v-btn color="grey" @click="addCategory()">+ cat Hinzufügen</v-btn>
      </div>


      <v-divider style="margin: 20px 0"></v-divider>
      <v-btn style="color: white" color="var(--Primary)" @click="save">
        Speichern
      </v-btn>

    </div>


  </div>
</template>

<script>
import logo from "../assets/CeeYuu_Logo_100x100.png";
import axios from "axios";
import {getUser} from "@/util/Auth";
import CeeYuuFooter2 from "@/components/headerfooter/CeeYuuFooter2";
import CeeYuuFooter from "@/components/headerfooter/CeeYuuFooter";
import {getAll, getAllByFID, updateProduct} from "@/util/productUtil";
import {getAllAllergien} from "@/util/AllergeneUtil";


export default {
  name: "tv3",

  components: {
    CeeYuuFooter2,
    CeeYuuFooter,
  },
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      product: {
        category: [{
          name: "",
          tv: "3",
          productList: [{
            nr: "",
            product: "",
            description: "",
            price: "",
            allergien: [],
            zusatzstoffe: []
          }]

        }]
      },

      allergien: [],
      zusatzstoffe: [],
      allergienList: [],
      buchstaben: [],
      zahlen: [],
      tv: "3",
      productCounter:[0,0,0,0,0,0,0,6,4,4,7],
      anzahlCategorienTV3:4,
      categoryCounter:0

    };
  },
  methods: {
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;

    },
    async save() {
      let o = this.product

      try {
        await updateProduct(o);
        this.notification("Speichern erfolgreich", "green");
      } catch (e) {

      }

    },
    addProduct(catind) {
      this.product.category[catind].productList.push({
        nr: "",
        product: "",
        description: "",
        price: "",
        allergien: [],
        zusatzstoffe: []
      });

    },

    addCategory() {
      this.product.category.push({
        name: "",
        tv: this.tv,
        productList: [{
          nr: "",
          product: "",
          description: "",
          price: "",
          allergien: [],
          zusatzstoffe: []
        }]
      });

    }
  },
  created() {

  },
  async mounted() {
    //this.product = await getAll();
    this.product = this.product = await getAllByFID(localStorage.getItem("u"));
    let count = 0;
    this.product.category.map(item =>{
      if(item.tv ===this.tv)
      {
        count++;
      }
    });
    this.categoryCounter = count;

    this.allergienList = [];
    try {
      this.allergienList = await getAllAllergien();
      this.allergien = this.allergienList.allergien;
      this.allergien = this.allergien.filter(item => item.text !== "");
      this.zusatzstoffe = this.allergienList.zusatzstoffe;
      this.zusatzstoffe = this.zusatzstoffe.filter(item => item.text !== "");

    } catch (e) {

    }


  }
}
</script>

<style scoped>
@import "../css/TV1.css";

</style>
