import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Home from "@/views/Home";
import TV1 from "@/views/TV1";
import TV2 from "@/views/TV2";
import TV3 from "@/views/TV3";
import TV4 from "@/views/TV4";
import Zusatzstoffe from "@/views/Zusatzstoffe";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/tv1',
    name: 'TV1',
    component: TV1
  },
  {
    path: '/tv2',
    name: 'TV2',
    component: TV2
  },
  {
    path: '/tv3',
    name: 'TV3',
    component: TV3
  },
  {
    path: '/tv4',
    name: 'TV4',
    component: TV4
  },
  {
    path: '/zusatzstoffe',
    name: 'zusatzstoffe',
    component: Zusatzstoffe
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
