<template>
  <div>
    <nav class="div-nav-burgermenu" role="navigation">
      <div id="menuToggle">
        <input
            type="checkbox"
            :checked="checkbox"
            @click="checkbox = !checkbox"
        />
        <span></span>
        <span></span>
        <span></span>

        <ul id="menu">

          <div class="div-logo-nav-burgermenu">
            <v-icon class="icon-close-burgermenu" @click="checkbox = false">mdi-arrow-left</v-icon>
            <img class="img-logo-nav" :src="pLogo" alt="Logo"/>
          </div>


          <router-link

              to="/"
              tag="button"
              class="button-nav-desktop"
              active-class="button-nav-desktop-aktiv"
              exact
              @click.native="checkbox = false"
          >
            <v-icon class="icon-nav">mdi-home</v-icon>

            Start
<!--            {{ $t("Nav.Start") }}-->
          </router-link>

          <router-link

              to="/tv1"
              tag="button"
              class="button-nav-desktop"
              active-class="button-nav-desktop-aktiv"
              @click.native="checkbox = false"
          >
            <v-icon class="icon-nav">mdi-food</v-icon>
            TV 1
<!--            {{ $t("Nav.Reservierungen") }}-->
          </router-link>
          <router-link

              to="/tv2"
              tag="button"
              class="button-nav-desktop"
              active-class="button-nav-desktop-aktiv"
              @click.native="checkbox = false"
          >
            <v-icon class="icon-nav">mdi-food</v-icon>
            TV 2
<!--            {{ $t("Nav.Reservierungen") }}-->
          </router-link>
          <router-link

              to="/tv3"
              tag="button"
              class="button-nav-desktop"
              active-class="button-nav-desktop-aktiv"
              @click.native="checkbox = false"
          >
            <v-icon class="icon-nav">mdi-food</v-icon>
            TV 3
<!--            {{ $t("Nav.Reservierungen") }}-->
          </router-link>
          <router-link

              to="/tv4"
              tag="button"
              class="button-nav-desktop"
              active-class="button-nav-desktop-aktiv"
              @click.native="checkbox = false"
          >
            <v-icon class="icon-nav">mdi-food</v-icon>
            TV 4
<!--            {{ $t("Nav.Reservierungen") }}-->
          </router-link>
          <router-link

              to="/zusatzstoffe"
              tag="button"
              class="button-nav-desktop"
              active-class="button-nav-desktop-aktiv"
              @click.native="checkbox = false"
          >
            <v-icon class="icon-nav">mdi-flower-pollen</v-icon>
            Zusatzstoffe
<!--            {{ $t("Nav.Reservierungen") }}-->
          </router-link>



<!--          <router-link-->
<!--              v-if="pRolle==='reiseburo' || pRolle==='admin' || pRolle==='superadmin'"-->
<!--              to="/dashboard"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop"-->
<!--              active-class="button-nav-desktop-aktiv"-->
<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-finance</v-icon>-->
<!--            {{ $t("Nav.Dashboard") }}-->
<!--          </router-link>-->


<!--          <router-link-->
<!--              v-if="pRolle==='hotel' || pRolle==='admin' || pRolle==='superadmin'"-->
<!--              to="/hotellist"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop"-->
<!--              active-class="button-nav-desktop-aktiv"-->
<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-home-city</v-icon>-->
<!--            {{ $t("Nav.EigeneHotels") }}-->
<!--          </router-link>-->


<!--          <router-link-->
<!--              v-if="pRolle==='hotel' || pRolle==='admin' || pRolle==='superadmin'"-->
<!--              to="/createhotel"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop button-nav-desktop1"-->
<!--              active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-home-plus</v-icon>-->
<!--            {{ $t("Nav.HotelAnlegen") }}-->
<!--          </router-link>-->

<!--          <router-link-->
<!--              v-if="pRolle==='hotel' || pRolle==='admin' || pRolle==='superadmin'"-->
<!--              to="/createroom"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop button-nav-desktop1"-->
<!--              active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-bed-king-outline</v-icon>-->
<!--            {{ $t("Nav.ZimmerAnlegen") }}-->
<!--          </router-link>-->
<!--          &lt;!&ndash;          <router-link&ndash;&gt;-->
<!--          &lt;!&ndash;              v-if="pRolle==='admin' || pRolle==='superadmin'"&ndash;&gt;-->
<!--          &lt;!&ndash;              to="/createtransfer"&ndash;&gt;-->
<!--          &lt;!&ndash;              tag="button"&ndash;&gt;-->
<!--          &lt;!&ndash;              class="button-nav-desktop button-nav-desktop1"&ndash;&gt;-->
<!--          &lt;!&ndash;              active-class="button-nav-desktop-aktiv  button-nav-desktop1"&ndash;&gt;-->
<!--          &lt;!&ndash;              @click.native="checkbox = false"&ndash;&gt;-->
<!--          &lt;!&ndash;          >&ndash;&gt;-->
<!--          &lt;!&ndash;            <v-icon class="icon-nav">mdi-bus-side</v-icon>&ndash;&gt;-->
<!--          &lt;!&ndash;            {{ $t("Nav.TransferAnlegen") }}&ndash;&gt;-->
<!--          &lt;!&ndash;          </router-link>&ndash;&gt;-->


<!--          <router-link-->
<!--              v-if="pRolle==='admin' || pRolle==='superadmin'"-->
<!--              to="/accounts"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop"-->
<!--              active-class="button-nav-desktop-aktiv"-->
<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-account-multiple</v-icon>-->
<!--            {{ $t("Nav.Benutzer") }}-->
<!--          </router-link>-->


<!--          <router-link-->
<!--              to="/settings"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop"-->
<!--              active-class="button-nav-desktop-aktiv"-->
<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-cog</v-icon>-->
<!--            {{ $t("Nav.Einstellungen") }}-->
<!--          </router-link>-->


<!--          <router-link-->
<!--              v-if="pRolle==='admin' || pRolle==='superadmin'"-->
<!--              to="/settingsbooking"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop button-nav-desktop1"-->
<!--              active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-sack-percent</v-icon>-->
<!--            {{ $t("Nav.BuchungProvision") }}-->
<!--          </router-link>-->


<!--          <router-link-->
<!--              v-if="pRolle==='admin' || pRolle==='superadmin'"-->
<!--              to="/settingskeys"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop button-nav-desktop1"-->
<!--              active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-key-variant</v-icon>-->
<!--            {{ $t("Nav.SchlusselKey") }}-->
<!--          </router-link>-->


<!--          <router-link-->
<!--              v-if="pRolle==='admin' || pRolle==='superadmin'"-->
<!--              to="/settingsdesign"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop button-nav-desktop1"-->
<!--              active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-image-multiple</v-icon>-->
<!--            Design-->
<!--          </router-link>-->


<!--          <router-link-->
<!--              v-if="pRolle==='admin' || pRolle==='superadmin'"-->
<!--              to="/settingsFavorites"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop button-nav-desktop1"-->
<!--              active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-map-marker-star-outline</v-icon>-->
<!--            Favoriten-->
<!--          </router-link>-->


<!--          <router-link-->
<!--              v-if="pRolle==='superadmin'"-->
<!--              to="/settingsplace"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop button-nav-desktop1"-->
<!--              active-class="button-nav-desktop-aktiv button-nav-desktop1"-->

<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-home-edit</v-icon>-->
<!--            {{ $t("Nav.HotelEinstellungen") }}-->
<!--          </router-link>-->

<!--          <router-link-->
<!--              v-if="pRolle==='superadmin'"-->
<!--              to="/test"-->
<!--              tag="button"-->
<!--              class="button-nav-desktop"-->
<!--              active-class="button-nav-desktop-aktiv"-->
<!--              @click.native="checkbox = false"-->
<!--          >-->
<!--            <v-icon class="icon-nav">mdi-alien</v-icon>-->
<!--            Test-->
<!--          </router-link>-->


          <v-divider></v-divider>
          <br/>


          <div class="div-language-burgermenu">
<!--            <LanguageSwitcher/>-->
          </div>


          <div class="div-language-burgermenu">
<!--            <Currency/>-->
          </div>


          <router-link
              to=""
              tag="button"
              class="button-nav-desktop"
              @click.native="logout"
              style="margin-bottom: 100px;"
          >
            <v-icon class="icon-nav">mdi-logout-variant</v-icon>
            Logout
<!--            {{ $t("Logout") }}-->
          </router-link>

        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
// import LanguageSwitcher from "@/components/languageSwitcher/LanguageSwitcher";
// import Currency from "@/components/Currency/Currency";

export default {
  name: "BurgerMenu",
  props: [
    'pLang',
    'pRolle',
    'pLogo'
  ],
  // components: {
  //   LanguageSwitcher,
  //   Currency
  // },
  data() {
    return {
      checkbox: false,
    };
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      localStorage.removeItem("user");
      localStorage.removeItem("tid");
      this.$router.push("/login");
    }
  }
}
;
</script>

<style scoped>
@import "../../css/BurgerMenu.css";
@import "../../css/NavLeft.css";
</style>
