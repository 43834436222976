import axios from "axios";

export const getAllAllergien = async () =>{

    let res = await axios.get(process.env.VUE_APP_DB + "/api/allergen", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    let allergien = res.data.data;

    return allergien[0];
}

export const updateAllergien = async (allergieObject) => {
    let status = 0;
    console.log('place');
    console.log(allergieObject);

    await axios.put(process.env.VUE_APP_DB + "/api/allergen/" + allergieObject._id, allergieObject, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {
    });
    return status;
}