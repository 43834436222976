import axios from "axios";

export const result = async (token) => {
    try {
        let res = await axios.get(process.env.VUE_APP_DB + "/api/auth", {
            headers: {
                "x-auth-token": token,
                "Access-Control-Allow-Origin": "*"
            }
            //"x-auth-token": 'Bearer '+token
        });

        return res.status === 200;

    } catch (e) {
        return false;
    }
}


export const getUser = async (token) => {
    try {
        let user = await axios.get(process.env.VUE_APP_DB + "/api/auth", {
            headers: {
                "x-auth-token": token,
                "Access-Control-Allow-Origin": "*"
            }
        });

        return user.data;

    } catch (e) {
    }
}


