<template>
  <nav class="div-nav-desktop">
    <div class="div-nav-desktop-1">

      <div class="div-logo-nav">
        <img class="img-logo-nav" :src="pLogo" alt="Logo" @click="zuStart"/>
      </div>


      <router-link
          to="/"
          tag="button"
          class="button-nav-desktop"
          active-class="button-nav-desktop-aktiv"
          exact
      >
        <div @click="deactivateSubMenu">
          <v-icon class="icon-nav">mdi-home</v-icon>
          Start
<!--          {{ $t("Nav.Start") }}-->
        </div>
      </router-link>

      <router-link
          to="/tv1"
          tag="button"
          class="button-nav-desktop"
          active-class="button-nav-desktop-aktiv"
      >
        <div @click="deactivateSubMenu">
          <v-icon class="icon-nav">mdi-food</v-icon>
          TV 1
<!--          {{ $t("Nav.Reservierungen") }}-->
        </div>
      </router-link>

      <router-link
          to="/tv2"
          tag="button"
          class="button-nav-desktop"
          active-class="button-nav-desktop-aktiv"
      >
        <div @click="deactivateSubMenu">
          <v-icon class="icon-nav">mdi-food</v-icon>
          TV 2
<!--          {{ $t("Nav.Reservierungen") }}-->
        </div>
      </router-link>

      <router-link
          to="/tv3"
          tag="button"
          class="button-nav-desktop"
          active-class="button-nav-desktop-aktiv"
      >
        <div @click="deactivateSubMenu">
          <v-icon class="icon-nav">mdi-food</v-icon>
          TV 3
<!--          {{ $t("Nav.Reservierungen") }}-->
        </div>
      </router-link>

      <router-link
          to="/tv4"
          tag="button"
          class="button-nav-desktop"
          active-class="button-nav-desktop-aktiv"
      >
        <div @click="deactivateSubMenu">
          <v-icon class="icon-nav">mdi-food</v-icon>
          TV 4
<!--          {{ $t("Nav.Reservierungen") }}-->
        </div>
      </router-link>
      <router-link
          to="/zusatzstoffe"
          tag="button"
          class="button-nav-desktop"
          active-class="button-nav-desktop-aktiv"
      >
        <div @click="deactivateSubMenu">
          <v-icon class="icon-nav">mdi-flower-pollen</v-icon>
          Zusatzstoffe
<!--          {{ $t("Nav.Reservierungen") }}-->
        </div>
      </router-link>


<!--      <router-link-->
<!--          v-if="pRolle==='reiseburo' || pRolle==='admin' || pRolle==='superadmin'"-->
<!--          to="/dashboard"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop"-->
<!--          active-class="button-nav-desktop-aktiv"-->
<!--      >-->
<!--        <div @click="deactivateSubMenu">-->
<!--          <v-icon class="icon-nav">mdi-finance</v-icon>-->
<!--          {{ $t("Nav.Dashboard") }}-->
<!--        </div>-->
<!--      </router-link>-->


<!--      <router-link-->
<!--          v-if="pRolle==='hotel' || pRolle==='admin' || pRolle==='superadmin'"-->
<!--          to="/hotellist"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop"-->
<!--          active-class="button-nav-desktop-aktiv"-->
<!--      >-->
<!--        <div @click="activateHotel">-->
<!--          <v-icon class="icon-nav">mdi-home-city</v-icon>-->
<!--          {{ $t("Nav.EigeneHotels") }}-->
<!--        </div>-->
<!--      </router-link>-->


<!--      <router-link-->
<!--          v-if="pRolle==='hotel' || pRolle==='admin' || pRolle==='superadmin'"-->
<!--          to="/createhotel"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop button-nav-desktop1"-->
<!--          active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--      >-->
<!--        <v-icon class="icon-nav">mdi-home-plus</v-icon>-->
<!--        {{ $t("Nav.HotelAnlegen") }}-->
<!--      </router-link>-->

<!--      <router-link-->
<!--          v-if="pRolle==='hotel' || pRolle==='admin' || pRolle==='superadmin'"-->
<!--          to="/createroom"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop button-nav-desktop1"-->
<!--          active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--      >-->
<!--        <v-icon class="icon-nav">mdi-bed-king-outline</v-icon>-->
<!--        {{ $t("Nav.ZimmerAnlegen") }}-->
<!--      </router-link>-->

<!--      &lt;!&ndash;            <router-link&ndash;&gt;-->
<!--      &lt;!&ndash;                v-if="pRolle==='admin' || pRolle==='superadmin'"&ndash;&gt;-->
<!--      &lt;!&ndash;                to="/createtransfer"&ndash;&gt;-->
<!--      &lt;!&ndash;                tag="button"&ndash;&gt;-->
<!--      &lt;!&ndash;                class="button-nav-desktop button-nav-desktop1"&ndash;&gt;-->
<!--      &lt;!&ndash;                active-class="button-nav-desktop-aktiv button-nav-desktop1"&ndash;&gt;-->
<!--      &lt;!&ndash;            >&ndash;&gt;-->
<!--      &lt;!&ndash;              <v-icon class="icon-nav">mdi-bus-side</v-icon>&ndash;&gt;-->
<!--      &lt;!&ndash;              {{ $t("Nav.TransferAnlegen") }}&ndash;&gt;-->
<!--      &lt;!&ndash;            </router-link>&ndash;&gt;-->


<!--      <router-link-->
<!--          v-if="pRolle==='admin' || pRolle==='superadmin'"-->
<!--          to="/accounts"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop"-->
<!--          active-class="button-nav-desktop-aktiv"-->
<!--      >-->
<!--        <div @click="deactivateSubMenu">-->
<!--          <v-icon class="icon-nav">mdi-account-multiple</v-icon>-->
<!--          {{ $t("Nav.Benutzer") }}-->
<!--        </div>-->
<!--      </router-link>-->


<!--      <router-link-->
<!--          to="/settings"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop"-->
<!--          active-class="button-nav-desktop-aktiv"-->
<!--      >-->
<!--        <div @click="activateSettings">-->
<!--          <v-icon class="icon-nav">mdi-cog</v-icon>-->
<!--          {{ $t("Nav.Einstellungen") }}-->
<!--        </div>-->
<!--      </router-link>-->


<!--      <router-link-->
<!--          v-if="pRolle==='admin' || pRolle==='superadmin'"-->
<!--          to="/settingsbooking"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop button-nav-desktop1"-->
<!--          active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--      >-->
<!--        <v-icon class="icon-nav">mdi-sack-percent</v-icon>-->
<!--        {{ $t("Nav.BuchungProvision") }}-->
<!--      </router-link>-->


<!--      <router-link-->
<!--          v-if="pRolle==='admin' || pRolle==='superadmin'"-->
<!--          to="/settingskeys"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop button-nav-desktop1"-->
<!--          active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--      >-->
<!--        <v-icon class="icon-nav">mdi-key-variant</v-icon>-->
<!--        {{ $t("Nav.SchlusselKey") }}-->
<!--      </router-link>-->


<!--      <router-link-->
<!--          v-if="pRolle==='admin' || pRolle==='superadmin'"-->
<!--          to="/settingsdesign"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop button-nav-desktop1"-->
<!--          active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--      >-->
<!--        <v-icon class="icon-nav">mdi-image-multiple</v-icon>-->
<!--        Design-->
<!--      </router-link>-->


<!--      <router-link-->
<!--          v-if="pRolle==='admin' || pRolle==='superadmin'"-->
<!--          to="/settingsFavorites"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop button-nav-desktop1"-->
<!--          active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--      >-->
<!--        <v-icon class="icon-nav">mdi-map-marker-star-outline</v-icon>-->
<!--        Favoriten-->
<!--      </router-link>-->

<!--      <router-link-->
<!--          v-if="pRolle==='superadmin'"-->
<!--          to="/settingsplace"-->
<!--          tag="button"-->
<!--          class="button-nav-desktop button-nav-desktop1"-->
<!--          active-class="button-nav-desktop-aktiv button-nav-desktop1"-->
<!--      >-->
<!--        <v-icon class="icon-nav">mdi-home-edit</v-icon>-->
<!--        {{ $t("Nav.HotelEinstellungen") }}-->
<!--      </router-link>-->


      <router-link
          v-if="pRolle==='superadmin'"
          to="/test"
          tag="button"
          class="button-nav-desktop"
          active-class="button-nav-desktop-aktiv"
      >
        <v-icon class="icon-nav">mdi-alien</v-icon>
        Test
      </router-link>


    </div>
  </nav>
</template>

<script>

export default {
  name: "NavLeft",
  props: [
    'pLang',
    'pRolle',
    'pLogo'
  ],
  data() {
    return {
      settingsIsActiv: false,
      hotelIsActiv: false,

    };
  },
  methods: {
    zuStart() {
      this.$router.push("/");
    },
    activateSettings() {
      this.settingsIsActiv = true;
      this.hotelIsActiv = false;
    },
    activateHotel() {
      this.settingsIsActiv = false;
      this.hotelIsActiv = true;
    },
    deactivateSubMenu() {
      this.settingsIsActiv = false;
      this.hotelIsActiv = false

    }
  }
};
</script>

<style scoped>
@import "../../css/NavLeft.css";
</style>
