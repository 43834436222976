<template>
  <div class="">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        Schliessen
        <!--        {{ $t("Notification.Schliessen") }}-->
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <div class="bodyheader-zusatzstoffe">
      Allergene & Zusatzstoffe
    </div>

    <div class="div-zusatzstoffe">


      <div>
          <div>
          <div v-for="(item,index) in allergien" :key="item.id" >
            <div class="draggable-item-zusatzstoffe">

              <div style="display: grid; grid-template-columns:30px 1fr 30px; align-items: center">
                <div>
                  {{item.kurzel}}
                </div>
                <div>

                  <v-text-field
                      v-model="allergien[index].text"
                      solo
                      color="var(--Secondary)"
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>

      <div style="display: flex; justify-content: space-evenly">
        <v-btn style="color: white" large color="var(--Primary)" @click="saveAllergien">
          Speichern
        </v-btn>

      </div>
    </div>

    <v-divider ></v-divider>


    <div class="div-zusatzstoffe">


      <div>
          <div>
          <div v-for="(item, index) in zusatzstoffe" :key="item.id">
            <div class="draggable-item-zusatzstoffe">

              <div style="display: grid; grid-template-columns: 30px 1fr 30px; align-items: center">
                <div>
                  {{item.kurzel}}
                </div>
                <div>

                  <v-text-field
                      v-model="zusatzstoffe[index].text"
                      solo
                      color="var(--Secondary)"
                  />
                </div>

              </div>
            </div>
          </div>
          </div>
      </div>

      <div style="display: flex; justify-content: space-evenly">
        <v-btn style="color: white" large color="var(--Primary)" @click="saveZusatzstoffe">
          Speichern
        </v-btn>

      </div>
    </div>


  </div>
</template>

<script>
import logo from "../assets/CeeYuu_Logo_100x100.png";
import axios from "axios";
import {getUser} from "@/util/Auth";
import CeeYuuFooter2 from "@/components/headerfooter/CeeYuuFooter2";
import CeeYuuFooter from "@/components/headerfooter/CeeYuuFooter";
import {getAllAllergien, updateAllergien} from "@/util/AllergeneUtil";


export default {
  name: "zusatzstoffe",

  components: {
    CeeYuuFooter2,
    CeeYuuFooter,


  },
  // props: [
  //   'pDomain',
  // ],
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      allergien: [],
      zusatzstoffe: [],
      counter:0,
      zusCounter:0,



      buchstaben: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",],
      zahlen: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19, 20],

      dragallergien: true,
      dragzusatzsoffe: true,
      allergienList:[]

    };
  },
  methods: {
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;

    },
    async saveAllergien() {
      this.notification("Speichern erfolgreich", "green");
      this.allergienList.allergien = this.allergien;
     await updateAllergien(this.allergienList);

    },
   async saveZusatzstoffe() {
      this.notification("Speichern erfolgreich", "green");
      this.allergienList.zusatzstoffe = this.zusatzstoffe;
      await updateAllergien(this.allergienList);
    },
    addAllergie() {
      // this.notification("Löschen erfolgreich", "green");
      this.allergien.push({kurzel: this.buchstaben[++this.counter], text: ""});

    },
    addZusatzstoff() {
      // this.notification("Löschen erfolgreich", "green");

    },
    deleteAllergie(i) {
      // this.notification("Löschen erfolgreich", "green");
      this.allergien = this.allergien.filter(((aller,index) =>index != i ))

    },
    deleteZusatzstoffe() {
      this.notification("Löschen erfolgreich", "green");

    },

  },
  async created() {



  },

  async mounted(){
    this.allergienList = [];
    try {
      this.allergienList = await getAllAllergien();
    }
    catch(e){

    }



    if(this.allergienList.allergien.length === 0)
    {
      for(let i=0; i< this.buchstaben.length;i++)
      {
        this.allergien.push({kurzel: this.buchstaben[i], text: ""})
      }
    }
    else{
      this.allergien = this.allergienList.allergien;
    }



    if(this.allergienList.zusatzstoffe.length === 0)
    {
      for(let i=0; i< this.zahlen.length;i++)
      {
        this.zusatzstoffe.push({kurzel: this.zahlen[i], text: ""})
      }
    }
    else{
      this.zusatzstoffe = this.allergienList.zusatzstoffe;
    }


  }
}
</script>

<style scoped>
@import "../css/Zusatzstoffe.css";

</style>
