import axios from "axios";

export const getAll = async () =>{

    let res = await axios.get(process.env.VUE_APP_DB + "/api/products").catch(err => {
        return;
    });;
    let products = "";
    if(res !== undefined) {
        products = res.data.data;
    }

    return products[0];
}

export const getAllByFID = async (firma_id) =>{

    let res = await axios.get(process.env.VUE_APP_DB + "/api/productfid/" + firma_id, );
    return res.data.data;
}

export const updateProduct = async (productObject) => {
    let status = 0;



    await axios.put(process.env.VUE_APP_DB + "/api/product/" + productObject._id, productObject, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {
    });
    return status;
}