<template>
  <header class="div-header">

    <span class="span-burgermenu-hidden-header"></span>

    <BurgerMenu
        class="div-burger-header"
        :pLogo="pLogo"
        :pLang="pLang"
        :pRolle="pRolle"
    />
    <!--    <div class="div-burger-header">-->
    <!--      <BurgerLeft></BurgerLeft>-->
    <!--    </div>-->


    <label class="label-titel-header">
      <!--      <img class="img-logo-header" :src="logo" alt="Logo"/>-->
      www.koc-doner-welt.de
      <!--      {{ pDomain }}-->
    </label>

    <span class="span-section-header">

      <!--      <Currency/>-->
    </span>

    <span class="span-section-header">

      <!--      <LanguageSwitcher/>-->
    </span>


    <span class="span-section-header">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="logout">
            <v-icon color="var(--Primary)">mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
<!--        <span>{{ $t("Logout") }}</span>-->
      </v-tooltip>
    </span>


  </header>
</template>

<script>
import BurgerMenu from "../nav/BurgerMenu";
// import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";
import logo from "../../assets/CeeYuu_Logo_100x100.png";
// import Currency from "@/components/Currency/Currency";
// import BurgerLeft from "@/components/nav/BurgerLeft";

export default {
  name: "Header",
  props: [
    'pLang',
    'pRolle',
    'pLogo',
    'pDomain'
  ],
  components: {
    BurgerMenu,
    // LanguageSwitcher,
    // Currency,
    // BurgerLeft
  },
  data() {
    return {
      // logo: logo,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      localStorage.removeItem('user');
      this.$router.push('/login');
    }
  }

}
</script>

<style lang="css" scoped>
@import "../../css/Header.css";
</style>
