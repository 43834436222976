<template>
  <div class="home">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        Schliessen
<!--        {{ $t("Notification.Schliessen") }}-->
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <div>
      Home
    </div>



  </div>
</template>

<script>


export default {
  name: "Home",
  props: [
    'pRolle',
    'pLang',
    'pCurrencyValues',
    'pCurrency'
  ],
  components: {
    // CardPlace,
    // CY_CardPlace,
    // Search,
    // SkeletonCardHorizontal
  },
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

    };
  },
  methods: {

    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;

    },

  },

  async created() {

  }

};
</script>
<style scoped>

@import "../css/Home.css";
</style>